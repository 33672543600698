import React from 'react';

import {DATA_SOURCE_ONE_QUERY} from '../../../Graphql/query';
import {DATA_SOURCE_CONFIG_SET_MUTATION, DATA_SOURCE_STATUS_SET_MUTATION} from '../../../Graphql/mutation';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks';

import { MdSave } from 'react-icons/md';

import styles from './Edit.module.scss';

const ConfigCsv = ({dataSource}) => {
  const [config, setConfig] = React.useState({refresh_interval: '* * * * *', url: '', csv_delimiter: ';', csv_quote: '"', sku: '', name: ''});
  const [origConfig, setOrigConfig] = React.useState({refresh_interval: '* * * * *', url: '', csv_delimiter: ';', csv_quote: '"', sku: '', name: ''});


  const [setDataSourceConfig, { data, loading }] = useMutation(DATA_SOURCE_CONFIG_SET_MUTATION, {
    onCompleted: (data) => {
      setOrigConfig({...origConfig, [data.DataSourceConfigSet.id]: config[data.DataSourceConfigSet.id]})
    },
    variables: {
      dataSourceId: dataSource.id
    }
  });

  const saveConfig = (code, value) => {
    setDataSourceConfig({variables: {code, value}});
  }

  React.useEffect(() => {
    let newConfig = {...config}
    for(let configEntry of dataSource.config) {
      newConfig[configEntry['code']] = configEntry['value'];
    }

    setConfig(newConfig)
    setOrigConfig(newConfig)
  }, [dataSource])

  const configProps = {
    url: {value: config.url, onChange: (e) => setConfig({...config, url: e.target.value})},
    refresh_interval: {value: config.refresh_interval, onChange: (e) => setConfig({...config, refresh_interval: e.target.value})},
    csv_delimiter: {value: config.csv_delimiter, onChange: (e) => setConfig({...config, csv_delimiter: e.target.value})},
    csv_quote: {value: config.csv_quote, onChange: (e) => setConfig({...config, csv_quote: e.target.value})},
    sku: {value: config.sku, onChange: (e) => setConfig({...config, sku: e.target.value})},
    name: {value: config.name, onChange: (e) => setConfig({...config, name: e.target.value})},
  }

  return (
    <div>
      <h2>CSV Kofiguration</h2>
      <div className={styles['config-row']}>
        <div className={styles['config-legend']}>URL:</div>
        <form className={styles['config-input']} onSubmit={(e) => {
          e.preventDefault();
          saveConfig('url', config.url)
        }}>
          <input {...configProps.url} type='url' />
          {config.url != origConfig.url ? (<button><MdSave /></button>) : ''}
        </form>
      </div>

      <div className={styles['config-row']}>
        <div className={styles['config-legend']}>Abrufintervall:</div>
        <form className={styles['config-input']} onSubmit={(e) => {
          e.preventDefault();
          saveConfig('refresh_interval', config.refresh_interval)
        }}>
          <input type='text' {...configProps.refresh_interval} />
          {config.refresh_interval != origConfig.refresh_interval ? (<button><MdSave /></button>) : ''}
        </form>
      </div>

      <div className={styles['config-row']}>
        <div className={styles['config-legend']}>CSV Trennzeichen:</div>
        <form className={styles['config-input']} onSubmit={(e) => {
          e.preventDefault();
          saveConfig('csv_delimiter', config.csv_delimiter)
        }}>
          <input type='text' {...configProps.csv_delimiter} />{config.csv_delimiter != origConfig.csv_delimiter ? (<button><MdSave /></button>) : ''}
        </form>
      </div>
      <div className={styles['config-row']}>
        <div className={styles['config-legend']}>CSV Anführungszeichen:</div>
        <form className={styles['config-input']} onSubmit={(e) => {
          e.preventDefault();
          saveConfig('csv_quote', config.csv_quote)
        }}>
          <input type='text' {...configProps.csv_quote} />{config.csv_quote != origConfig.csv_quote ? (<button><MdSave /></button>) : ''}
        </form>
      </div>
      <div className={styles['config-row']}>
        <div className={styles['config-legend']}>CSV Spalte SKU:</div>
        <form className={styles['config-input']} onSubmit={(e) => {
          e.preventDefault();
          saveConfig('sku', config.sku)
        }}>
          <input type='text' {...configProps.sku} />{config.sku != origConfig.sku ? (<button><MdSave /></button>) : ''}
        </form>
      </div>
      <div className={styles['config-row']}>
        <div className={styles['config-legend']}>CSV Spalte SKU:</div>
        <form className={styles['config-input']} onSubmit={(e) => {
          e.preventDefault();
          saveConfig('name', config.name)
        }}>
          <input type='text' {...configProps.name} />{config.name != origConfig.name ? (<button><MdSave /></button>) : ''}
        </form>
      </div>
    </div>
  );
}

const ConfigApi = ({dataSource}) => {
  const [config, setConfig] = React.useState({token: ''});
  const [origConfig, setOrigConfig] = React.useState({token: ''});


  const [setDataSourceConfig, { data, loading }] = useMutation(DATA_SOURCE_CONFIG_SET_MUTATION, {
    onCompleted: (data) => {
      setOrigConfig({...origConfig, [data.DataSourceConfigSet.id]: config[data.DataSourceConfigSet.id]})
    },
    variables: {
      dataSourceId: dataSource.id
    }
  });

  const saveConfig = (code, value) => {
    setDataSourceConfig({variables: {code, value}});
  }

  React.useEffect(() => {
    let newConfig = {...config}
    for(let configEntry of dataSource.config) {
      newConfig[configEntry['code']] = configEntry['value'];
    }

    setConfig(newConfig)
    setOrigConfig(newConfig)
  }, [dataSource])

  const configProps = {
    token: {value: config.token, onChange: (e) => setConfig({...config, token: e.target.value})},
  }

  return (
    <div>
      <h2>CSV Kofiguration</h2>
      <div className={styles['config-row']}>
        <div className={styles['config-legend']}>Token:</div>
        <form className={styles['config-input']} onSubmit={(e) => {
          e.preventDefault();
          saveConfig('token', config.token)
        }}>
          <input {...configProps.token} type='text' />
          {config.token != origConfig.token ? (<button><MdSave /></button>) : ''}
        </form>
      </div>
    </div>
  );
}

const Config = ({dataSource}) => {
  if(dataSource.type === 'csv') return <ConfigCsv {...{dataSource}} />
  if(dataSource.type === 'api') return <ConfigApi {...{dataSource}} />
  else return 'Unbekannter Quell Typ'
}


const SetStatus = ({dataSource, refetch}) => {

  const [setDataSourceStatus, { data, loading }] = useMutation(DATA_SOURCE_STATUS_SET_MUTATION, {
    onCompleted: (data) => {
      refetch()
    },
    variables: {
      dataSourceId: dataSource.id
    }
  });

  return (
    <div>
      {dataSource.active ? (
        <button onClick={() => setDataSourceStatus({variables: {active: false}})}>Deaktivieren</button>
      ) : (
        <button onClick={() => setDataSourceStatus({variables: {active: true}})}>Aktivieren</button>
      )}
    </div>
  );
}

export default ({match}) => {
  const dataSourceId = match.params.id;
  const { loading, error, data, refetch } = useQuery(DATA_SOURCE_ONE_QUERY, {
    variables: {
      dataSourceId
    }
  });

  if(loading) return <div>Loading</div>

  const dataSource = data.dataSource;


  return (
    <div>
      <h1>{dataSource.name} - ({dataSource.type})</h1>
      <SetStatus {...{dataSource, refetch}} />
      <Config {...{dataSource}}/>
    </div>
  );
}
