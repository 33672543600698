import React from 'react';

import { Link } from 'react-router-dom';

import styles from './Produkte.module.scss'

import CompanyProvider from '../../Fragments/CompanyProvider';


import {DATA_SOURCE_LIST_QUERY, PRODUCTS_LIST_QUERY} from '../../Graphql/query';
import { useQuery } from '@apollo/react-hooks';


const Product = ({product}) => {

  const [showDetails, setShowDetails] = React.useState(false);

  return (
    <div style={{border: '1px solid black', margin: 10, padding: 10}}>

      <div onClick={() => setShowDetails(!showDetails)} style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid black', padding: 10}}>
        <div>{product.sku}</div>
        <div>{product.name}</div>
      </div>
      {showDetails ?  (
        <div>
          {product.attributes.map(attr => (
            <div key={attr.code}>
              {attr.code}: {attr.value}
            </div>
          ))}
        </div>
      ) : ''}
    </div>
  );
}

const Products = ({dataSourceId}) => {
  const { loading, error, data, refetch } = useQuery(PRODUCTS_LIST_QUERY, {
    variables: {
      dataSourceId
    }
  });




  let products = [];
  if(!loading && data.products && Array.isArray(data.products)) products = data.products;
  return (
    <div>
      {products.map(product => (
        <Product {...{product, key: product.id}} />
      ))}
    </div>
  );
}

const Component = ({company}) => {
  const [selectedSourceId, setSelectedSourceId] = React.useState('');
  const { loading, error, data, refetch } = useQuery(DATA_SOURCE_LIST_QUERY, {
    variables: {
      companyId: company.id
    }
  });

  let sources = [];

  if(!loading && data.dataSources && Array.isArray(data.dataSources)) {
    sources = data.dataSources;
  }
  return (
    <div>
      <h1>Produkte</h1>
      <select value={selectedSourceId} onChange={(e) => setSelectedSourceId(e.target.value)}>
        <option/>
        {sources.map(source => (
          <option disabled={!source.active} key={source.id} value={source.id}>{source.name}</option>
        ))}
      </select>
      <Products dataSourceId={selectedSourceId} />
    </div>
  );
}

export default () => {
  return <CompanyProvider component={Component} />
}
