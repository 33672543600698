import React from 'react';

import { Jwt } from 'keme-react';

import { GET_ME } from '../Graphql/query';
import { useCookies } from 'react-cookie'


import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';



export default ({component}) => {
  const Child = component;

  const { loading, error, data } = useQuery(GET_ME);

  const [cookies, setCookie, removeCookie] = useCookies(['feed-company-id']);

  const reset = () => {
    removeCookie('feed-company-id');
  }

  if(loading) return <div>Loading...</div>;

  let company = false;
  const currentValue = cookies['feed-company-id'] || false;


  for(let c of data.me.companies) {
    if(c.id == currentValue) {
      company = c;
    }
  }

  if(!company) return ''


  return <Child {...{company, reset}} />

}
