import React from 'react';

import CompanyProvider from '../../Fragments/CompanyProvider';
import { Link } from 'react-router-dom';

import {FEED_CREATE_MUTATION, FEED_DELETE_MUTATION} from '../../Graphql/mutation';
import {GET_ME} from '../../Graphql/query';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { MdDelete, MdEdit } from 'react-icons/md'

import { ConfirmDialog } from 'keme-react';

const styles = {};

const FeedActions = ({company, feed}) => {
  const [deleteFeed, { data, loading }] = useMutation(FEED_DELETE_MUTATION, {
    refetchQueries: [{
      query: GET_ME
    }],
    variables: {
      feedId: feed.id
    }
  });
  return (
    <React.Fragment>
      <div className={styles['action']}><Link to={`/feeds/${feed.id}`}><MdEdit /></Link></div>
      <div className={styles['action']}><ConfirmDialog onClick={() => {deleteFeed()}} message={`Möchtest du den Feed "${feed.name}" wirklich löschen?`}><MdDelete /></ConfirmDialog></div>
    </React.Fragment>
  )

}

const Component = ({company}) => {

  const [name, setName] = React.useState('');

  const [createFeed, { data, loading }] = useMutation(FEED_CREATE_MUTATION, {
    refetchQueries: [{
      query: GET_ME
    }],
    variables: {
      companyId: company.id,
      name
    }
  });

  let feeds = [];

  if(company.feeds && Array.isArray(company.feeds)) feeds = company.feeds;

  return (
    <div>
      <h1>Feed</h1>

      <div>
        {feeds.map(feed => (
          <div key={feed.id} style={{border: '1px solid black', padding: 5, margin: 5, display: 'flex', justifyContent: 'space-between'}}>
            <div style={{width: '100%'}}>{feed.name}</div>
            <FeedActions {...{company, feed}} />
          </div>
        ))}
      </div>

      <div>
        <h2>Neuer Feed</h2>
        <form onSubmit={(e) => {
          e.preventDefault();
          createFeed();
        }}>
          <div><input type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='Name ...' /></div>
          <div><button>anlegen</button></div>
        </form>
      </div>
    </div>
  );
}

export default () => {
  return <CompanyProvider component={Component} />
}
