import React from 'react';

import { Link } from 'react-router-dom';

import styles from './DataSource.module.scss'

import CompanyProvider from '../../Fragments/CompanyProvider';

import {DATA_SOURCE_CREATE_MUTATION, DATA_SOURCE_DELETE_MUTATION} from '../../Graphql/mutation';
import { useMutation } from '@apollo/react-hooks';
import {DATA_SOURCE_LIST_QUERY} from '../../Graphql/query';
import { useQuery } from '@apollo/react-hooks';

import { MdDelete, MdEdit } from 'react-icons/md'

import { ConfirmDialog } from 'keme-react';

const Create = ({company, refetch}) => {
  const [name, setName] = React.useState('');
  const [type, setType] = React.useState('csv');

  const [createDataSource, { data, loading }] = useMutation(DATA_SOURCE_CREATE_MUTATION, {
    onCompleted: (data) => {
      refetch()
    },
    variables: {
      name, type,
      companyId: company.id
    }
  });

  return (
    <div className={styles['create']}>
      <h3>Neue Datenquelle</h3>
      <div className={styles['form-container']}>
        <form onSubmit={(e) => {
          e.preventDefault();
          createDataSource();
        }}>
          <div><input value={name} onChange={(e) => setName(e.target.value)} placeholder='Name ...' /></div>
          <div>
            <select value={type} onChange={(e) => setType(e.target.value)}>
              <option />
              <option value='csv'>CSV</option>
              <option value='api'>API</option>
            </select>
          </div>
          <div><button>Anlegen</button></div>
        </form>
      </div>
    </div>
  );
}


const List = ({company, sources, refetch}) => {

  const [deleteDataSource, { data, loading }] = useMutation(DATA_SOURCE_DELETE_MUTATION, {
    onCompleted: (data) => {
      refetch()
    }
  });

  return (
    <div className={styles['list']}>
      {sources.map((source => (
        <div key={source.id} className={styles['source']}>
          <div>{source.name}</div>
          <div>{source.type}</div>
          <div>
            <div className={styles['action']}><Link to={`/sources/${source.id}`}><MdEdit /></Link></div>
            <div className={styles['action']}><ConfirmDialog onClick={() => {deleteDataSource({variables: {dataSourceId: source.id}})}} message={`Möchtest du die Datenquelle "${source.name}" wirklich löschen?`}><MdDelete /></ConfirmDialog></div>
          </div>
        </div>
      )))}
    </div>
  );
}


const Component = ({company}) => {
  const { loading, error, data, refetch } = useQuery(DATA_SOURCE_LIST_QUERY, {
    variables: {
      companyId: company.id
    }
  });

  let sources = [];

  if(!loading && data.dataSources && Array.isArray(data.dataSources)) {
    sources = data.dataSources;
  }
  return (
    <div>
      <h1>Sources</h1>

      <List {...{company, sources, refetch}} />
      <Create {...{company, refetch}} />
    </div>
  );
}

export default () => {
  return <CompanyProvider component={Component} />
}
