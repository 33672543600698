import { gql } from 'apollo-boost';

export const GET_ME = gql`
  query getMe {
    me {
      email
      name
      roles
      companies {
        id
        name
        feeds {
          id
          name
        }
      }
    }
  }
`;

export const DATA_SOURCE_LIST_QUERY = gql`
  query dataSources($companyId: String!) {
    dataSources(companyId: $companyId) {
      id
      name
      type
      active
    }
  }
`;

export const DATA_SOURCE_ONE_QUERY = gql`
  query dataSource($dataSourceId: String!) {
    dataSource(dataSourceId: $dataSourceId) {
      id
      name
      type
      active
      config {
        code
        value
      }
    }
  }
`;

export const PRODUCTS_LIST_QUERY = gql`
  query products($dataSourceId: String!) {
    products(dataSourceId: $dataSourceId) {
      id
      sku
      name
      attributes {
        code
        value
      }
    }
  }
`;
export const FEED_ONE_QUERY = gql`
  query feed($feedId: String!) {
    feed(feedId: $feedId) {
      id
      name
      productFilterJs
      fileName
      lastUpdated
      config {
        code
        value
      }
      fields {
        id
        name
        path
        sortOrder
        valueScript
      }
    }
  }
`;
