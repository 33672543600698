import React from 'react';

import { ConfirmDialog } from 'keme-react';
import { MdDelete } from 'react-icons/md';

import {FEED_ONE_QUERY} from '../../../Graphql/query';
import {FEED_CONFIG_SET_MUTATION, FEED_FIELD_CREATE_MUTATION, FEED_FIELD_UPDATE_MUTATION, FEED_FIELD_DELETE_MUTATION} from '../../../Graphql/mutation';
import { useQuery, useMutation } from '@apollo/react-hooks';

import {config as sysConfig} from '../../../Config';

import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";

import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/worker-javascript";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-clouds";


import { MdSave } from 'react-icons/md';

const styles = {}

const Config = ({feed}) => {
  const initConfig = {refresh_interval: '* * * * *',type: ''}
  const [config, setConfig] = React.useState(initConfig);
  const [origConfig, setOrigConfig] = React.useState(initConfig);


  const [setFeedConfig, { data, loading }] = useMutation(FEED_CONFIG_SET_MUTATION, {
    onCompleted: (data) => {
      setOrigConfig({...origConfig, [data.FeedConfigSet.id]: config[data.FeedConfigSet.id]})
    },
    variables: {
      feedId: feed.id
    }
  });

  const saveConfig = (code, value) => {
    setFeedConfig({variables: {code, value}});
  }

  React.useEffect(() => {
    let newConfig = {...config}
    for(let configEntry of feed.config) {
      newConfig[configEntry['code']] = configEntry['value'];
    }

    setConfig(newConfig)
    setOrigConfig(newConfig)
  }, [feed])

  const configProps = {
    refresh_interval: {value: config.refresh_interval, onChange: (e) => setConfig({...config, refresh_interval: e.target.value})},
    type: {value: config.type, onChange: (e) => setConfig({...config, type: e.target.value})},
  }


  return (
    <div>
      <div className={styles['config-row']}>
        <a href={`${sysConfig.graphql.public}/${feed.fileName}`} target="_blank">{sysConfig.graphql.public}/{feed.fileName}</a>
      </div>
      <h2>CSV Kofiguration</h2>


      <div className={styles['config-row']}>
        <div className={styles['config-legend']}>Feed Art (csv/xml):</div>
        <form className={styles['config-input']} onSubmit={(e) => {
          e.preventDefault();
          saveConfig('type', config.type)
        }}>
          <input type='text' {...configProps.type} />
          {config.type != origConfig.type ? (<button><MdSave /></button>) : ''}
        </form>
      </div>

      <div className={styles['config-row']}>
        <div className={styles['config-legend']}>Aktualisierungsinterval:</div>
        <form className={styles['config-input']} onSubmit={(e) => {
          e.preventDefault();
          saveConfig('refresh_interval', config.refresh_interval)
        }}>
          <input type='text' {...configProps.refresh_interval} />
          {config.refresh_interval != origConfig.refresh_interval ? (<button><MdSave /></button>) : ''}
        </form>
      </div>
    </div>
  );
}

const ProductFilter = ({feed}) => {

  const [js, setJs] = React.useState(feed.productFilterJs || 'true;');

  const [setFeedConfig, { data, loading }] = useMutation(FEED_CONFIG_SET_MUTATION, {
    variables: {
      feedId: feed.id,
      code: 'productFilterJs',
      value: js
    }
  });


  return (
    <React.Fragment>
      <h2>Produkte auswählen</h2>
      <AceEditor
        mode="javascript"
        theme="clouds"
        height={'100px'}
        onChange={(v) => setJs(v)}
        value={js}
        name="feed_product_filter"
        editorProps={{ $blockScrolling: true }}
      />
      <button onClick={() => {
        setFeedConfig();
      }}><MdSave /></button>
    </React.Fragment>
  );
}


const NewField = ({feed}) => {
  const [name, setName] = React.useState('');
  const [sortOrder, setSortOrder] = React.useState(0);

  const [createField, { data, loading }] = useMutation(FEED_FIELD_CREATE_MUTATION, {
    refetchQueries: [{
      query: FEED_ONE_QUERY, variables: {feedId: feed.id}
    }],
    variables: {
      name, sortOrder,
      feedId: feed.id
    }
  });
  return (
    <div>
      <h3>Neues Feld</h3>
      <form onSubmit={(e) => {
        e.preventDefault();
        createField()
      }}>
        <div><input type={'number'} value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} placeholder={'Sortierung ...'} /></div>
        <div><input type={'text'} value={name} onChange={(e) => setName(e.target.value)} placeholder={'Name ...'} /></div>
        <div><button>hinzufügen</button></div>
      </form>
    </div>
  );
}

const Field = ({feed, field}) => {
  const [showDetails, setShowDetails] = React.useState(false);
  const [path, setPath] = React.useState(field.path)
  const [sortOrder, setSortOrder] = React.useState(field.sortOrder)
  const [valueScript, setValueScript] = React.useState(field.valueScript)


  const [updateField, { data, loading }] = useMutation(FEED_FIELD_UPDATE_MUTATION, {
    refetchQueries: [{
      query: FEED_ONE_QUERY, variables: {feedId: feed.id}
    }],
    variables: {
      fieldId: field.id,
      path, sortOrder, valueScript
    }
  });

  const [deleteField, result] = useMutation(FEED_FIELD_DELETE_MUTATION, {
    refetchQueries: [{
      query: FEED_ONE_QUERY, variables: {feedId: feed.id}
    }],
    variables: {
      fieldId: field.id
    }
  });

  return (
    <div style={{margin: 10, border: '1px solid black', padding: 10}}>
      <div onClick={() => setShowDetails(!showDetails)}>{field.sortOrder}. {field.name}</div>
      {showDetails ? (
        <div style={{marginLeft: 10}}>
          <form onSubmit={(e) => {
            e.preventDefault();
            updateField();
          }}>
            <div><label>SortOrder: <input type={'text'} value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} /></label></div>
            <div><label>Path: <input type={'text'} value={path} onChange={(e) => setPath(e.target.value)} /></label></div>
            <div>
              <AceEditor
                mode="javascript"
                theme="clouds"
                height={'100px'}
                onChange={(v) => setValueScript(v)}
                value={valueScript}
                name={`feed_field_js_${field.id}`}
                editorProps={{ $blockScrolling: true }}
              />
            </div>
            <div>
              <button>Speichern</button>
            </div>
          </form>
          <ConfirmDialog onClick={() => deleteField()} message={`Feld "${field.name}" wirklich löschen?`}>
            <MdDelete />
          </ConfirmDialog>
        </div>
      ) : ''}
    </div>
  )
}

const Fields = ({feed}) => {
  return (
    <React.Fragment>
      <div>
        <h2>Feld Konfiguration</h2>

        <div>
          {feed.fields.map(field => <Field key={field.id} {...{field, feed}} />)}
        </div>

        <NewField {...{feed}} />
      </div>
    </React.Fragment>
  );
}

export default ({match}) => {
  const feedId = match.params.id;
  const { loading, error, data, refetch } = useQuery(FEED_ONE_QUERY, {
    variables: {
      feedId
    }
  });

  if(loading) return <div>Loading ...</div>;

  let feed = data.feed;


  return (
    <div>
      <h1>Feed: {feed.name}</h1>
      <p>Letzte aktualisierung: {feed.lastUpdated}</p>

      <ProductFilter {...{feed}} />

      <Config {...{feed}} />
      <Fields {...{feed}} />
    </div>
  );
}
