import { gql } from 'apollo-boost';

export const DATA_SOURCE_CREATE_MUTATION = gql`
  mutation DataSourceCreate($companyId: String!, $name: String!, $type: String!) {
    DataSourceCreate(companyId: $companyId, name: $name, type: $type) {
      success
      message
      id
    }
  }
`

export const DATA_SOURCE_DELETE_MUTATION = gql`
  mutation DataSourceDelete($dataSourceId: String!) {
    DataSourceDelete(dataSourceId: $dataSourceId) {
      success
      message
    }
  }
`

export const DATA_SOURCE_CONFIG_SET_MUTATION = gql`
  mutation DataSourceConfigSet($dataSourceId: String!, $code: String!, $value: String) {
    DataSourceConfigSet(dataSourceId: $dataSourceId, code: $code, value: $value) {
      success
      message
      id
    }
  }
`
export const DATA_SOURCE_STATUS_SET_MUTATION = gql`
  mutation DataSourceStatusSet($dataSourceId: String!, $active: Boolean!) {
    DataSourceStatusSet(dataSourceId: $dataSourceId, active: $active) {
      success
      message
      id
    }
  }
`

export const FEED_CREATE_MUTATION = gql`
  mutation FeedCreate($companyId: String!, $name: String!) {
    FeedCreate(companyId: $companyId, name: $name) {
      success
      message
      id
    }
  }
`

export const FEED_DELETE_MUTATION = gql`
  mutation FeedDelete($feedId: String!) {
    FeedDelete(feedId: $feedId) {
      success
      message
      id
    }
  }
`

export const FEED_CONFIG_SET_MUTATION = gql`
  mutation FeedConfigSet($feedId: String!, $code: String!, $value: String) {
    FeedConfigSet(feedId: $feedId, code: $code, value: $value) {
      success
      message
      id
    }
  }
`

export const FEED_FIELD_CREATE_MUTATION = gql`
  mutation FeedFieldCreate($feedId: String!, $name: String!, $sortOrder: Int) {
    FeedFieldCreate(feedId: $feedId, name: $name, sortOrder: $sortOrder) {
      success
      message
      id
    }
  }
`

export const FEED_FIELD_UPDATE_MUTATION = gql`
  mutation FeedFieldUpdate($fieldId: String!, $path: String, $sortOrder: Int, $valueScript: String) {
    FeedFieldUpdate(fieldId: $fieldId, path: $path, sortOrder: $sortOrder, valueScript: $valueScript) {
      success
      message
      id
    }
  }
`

export const FEED_FIELD_DELETE_MUTATION = gql`
  mutation FeedFieldDelete($fieldId: String!) {
    FeedFieldDelete(fieldId: $fieldId) {
      success
      message
    }
  }
`
