import React from 'react';

import { config } from '../Config';


export default ({setToken}) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [message, setMessage] = React.useState(false);

  const handleChange = (field, value) => {
    if(field === 'email') setEmail(value);
    if(field === 'password') setPassword(value);

    setMessage(false);
  }

  return (
    <div>
      <h1>Login</h1>
      {message}
      <form onSubmit={(e) => {
        e.preventDefault();

        fetch(config.graphql.login, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: email,
            password: password,
          })
        }).then(resp => resp.json()).then(data => {
          if(data.message) {
            setMessage(data.message);
          }

          if(data.token) {
            setToken(data.token);
          }
        })

      }}>
        <div>
            <input type="email" required={true} value={email} onChange={(e) => handleChange('email', e.target.value)} placeholder="E-Mail" />
        </div>
        <div>
            <input type="password" required={true} value={password} onChange={(e) => handleChange('password', e.target.value)} placeholder="Passwort" />
        </div>
        <div><button>Login</button></div>
      </form>
    </div>
  );
}
