import React from 'react';

import { useCookies } from 'react-cookie'

import styles from './CompanySelector.module.scss';

import MeProvider from './MeProvider';

export default () => {
  const [cookies, setCookie, removeCookie] = useCookies(['feed-company-id']);

  const [selected, setSelected] = React.useState('');

  const currentValue = cookies['feed-company-id'] || false;

  if(currentValue) return '';


  return (
    <div className={styles['outer']}>
      <div className={styles['background']} />
      <div className={styles['inner']}>
        <h3>Firma auswählen</h3>
        <MeProvider component={({me}) => {
          return (
            <form onSubmit={(e) => {
              e.preventDefault();
              setCookie('feed-company-id', selected);
            }}>
              <div>
                <select value={selected} onChange={(e) => setSelected(e.target.value)}>
                  <option />
                  {me.companies.map(company => (
                    <option key={company.id} value={company.id}>{company.name}</option>
                  ))}
                </select>
              </div>
              <div><button>Übernehmen</button></div>
            </form>
          );
        }} />
      </div>


    </div>
  );
}
