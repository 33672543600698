import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Dashboard from '../Pages/Dashboard';
import Produkte from '../Pages/Produkte';
import DataSource from '../Pages/DataSource';
import DataSourceEdit from '../Pages/DataSource/Edit';
import Feed from '../Pages/Feed';
import FeedEdit from '../Pages/Feed/Edit';

export default (props) => (
  <div style={{width: '90vw', maxWidth: 1500, margin: 'auto'}}>
    <Router>
      {props.children}
      <Switch>
      <Route path="/sources/:id" component={DataSourceEdit} />
      <Route path="/sources" component={DataSource} />
      <Route path="/feeds/:id" component={FeedEdit} />
      <Route path="/feeds" component={Feed} />
      <Route path="/produkte" component={Produkte} />
      <Route path="/" component={Dashboard} />
      </Switch>
    </Router>
  </div>
)
