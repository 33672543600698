const local = {
  graphql: {
    url: 'http://localhost:8002/graphql/default',
    login: 'http://localhost:8002/api/login',
    test: 'http://localhost:8002/api/test',
    public: 'http://localhost:8002/feeds'
  }
}


const dev = {
  graphql: {
    url: 'https://api.feed.c1.ps30.de/graphql/default',
    login: 'https://api.feed.c1.ps30.de/api/login',
    test: 'https://api.feed.c1.ps30.de/api/test',
    public: 'https://api.feed.c1.ps30.de/feeds'
  }
}

const prod = {
  graphql: {
    url: 'https://api.feed.c1.ps30.de/graphql/default',
    login: 'https://api.feed.c1.ps30.de/api/login',
    test: 'https://api.feed.c1.ps30.de/api/test',
    public: 'https://api.feed.c1.ps30.de/feeds'
  }
}


export const config = process.env.REACT_APP_STAGE === 'local' ? local : (process.env.REACT_APP_STAGE === 'dev' ? dev : prod);
