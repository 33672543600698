import React from 'react';
import logo from './logo.svg';
import './App.css';


import { Nchan, Jwt } from 'keme-react';

import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

import Login from './Fragments/Login';
import Router from './Fragments/Router';
import MeProvider from './Fragments/MeProvider';
import CompanyProvider from './Fragments/CompanyProvider';
import CompanySelector from './Fragments/CompanySelector';
import { config } from './Config';



const checkLogin = ({setToken, logout, token}) => {
  fetch(config.graphql.test, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  }).then(response => response.json()).then(data => {
    if(data.ok) return;
    logout();
  })
}

const ApolloApp = (props) => {
  const {token} = Jwt.useToken();
  const [client, setClient] = React.useState(false);

  React.useEffect(() => {
    let apolloClient = new ApolloClient({
      uri: config.graphql.url,
      //cache,
      request: (operation) => {
        operation.setContext({
          headers: {
            authorization: token ? `Bearer ${token}` : ''
          }
        })
      }
    })
    setClient(apolloClient);
  }, [token]);

  if(!client) return (
    <React.Fragment>
      <div>Loading...</div>
    </React.Fragment>
  );
  return (
    <ApolloProvider client={client}>
      {props.children}
    </ApolloProvider>
  );
}

function App() {
  return (
    <div className="App">
      <header>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <a href={'/'}><strong>FEED - Engine</strong></a>
          <CompanyProvider component={({company, reset}) => (
            <div onClick={reset}>{company.name}</div>
          )} />
          <MeProvider component={({me, logout}) => (
            <div>{me.name} <button onClick={logout}>Logout</button></div>
          )} />
        </div>
        <hr />
      </header>
      <CompanySelector />
      <Router />
    </div>
  );
}

function Provider() {
  return (
    <Jwt.Provider loginComponent={Login} checkLogin={checkLogin} cookieName={'feed-token'}>
        <ApolloApp>
          <App />
        </ApolloApp>
    </Jwt.Provider>
  );
}

export default Provider;
