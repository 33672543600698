import React from 'react';

import { Link } from 'react-router-dom';

import styles from './Dashboard.module.scss'

export default () => {
  return (
    <div>
      <h1>Dashboard</h1>
      <div className={styles['tiles']}>
        <Link className={styles['tile']} to={'/produkte'}>Produkte</Link>
        <Link className={styles['tile']} to={'/feeds'}>Feeds</Link>
        <Link className={styles['tile']} to={'/sources'}>Sources</Link>
      </div>
    </div>
  );
}
