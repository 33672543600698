import React from 'react';

import { Jwt } from 'keme-react';

import { GET_ME } from '../Graphql/query';


import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export default ({component}) => {
  const Child = component;

  const { logout } = Jwt.useToken()

  const { loading, error, data } = useQuery(GET_ME);



  if(loading) return <div>Loading...</div>;
  return <Child me={data.me} logout={logout} />

}
